import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  POSITIONS = {
    "top": { top: 0, bottom: "unset", left: "unset", right: "unset" },
    "bottom": { top: "unset", bottom: 0, left: "unset", right: "unset" },
    "top-left": { top: 0, bottom: "unset", left: 0, right: "unset" },
    "top-right": { top: 0, bottom: "unset", left: "unset", right: 0 },
    "bottom-left": { top: "unset", bottom: 0, left: 0, right: "unset" },
    "bottom-right": { top: "unset", bottom: 0, left: "unset", right: 0 }
  };

  connect() {
    this.placement = this.element.querySelector("#live-preview-placement");
    this.gcBox = this.placement.querySelector(".gc-box");

    const style = this._getCheckedStyle();
    this.stylesJson = JSON.parse(style.dataset.cssStyle || "{}");

    const checkedTemplate = this._getCheckedTemplate();
    const css = this._removeStyles(this.stylesJson[`${checkedTemplate.value || "default"}`]);

    this._setPlacementCss(css, "#placement_css");
    this._applyStyle(style.value);
  }

  update(event) {
    const value = event.target.value;
    const target = event.target.dataset.livePreviewTarget;
    let style;
    if (this.placement == null || target == null) return;

    switch (target) {
      case "color":
        let colorAttribute = event.target.dataset.colorAttribute;
        this.placement.querySelector(".gc-box").style.setProperty(`--${colorAttribute}-color`, `${value}`);
        break;
      case "placement-tagline":
        this.placement.querySelector(".gc-tagline").textContent = value;
        break;
      case "cta-text":
        this.getMarkdown(value, ".gc-text");
        break;
      case "custom-css":
        this._setPlacementCss(value, "#custom_css");
        break;
      case "image-upload":
        this.previewImage(event.target.files[0]);
        break;
      case "completion-text":
        this.getMarkdown(value, ".gc-completion-text");
        break;
      case "style":
        const styleJson = JSON.parse(event.currentTarget.dataset.cssStyle || "{}");
        this.stylesJson = styleJson;
        const checkedTemplate = document.querySelector('[data-live-preview-target="template"]:checked');
        style = this._removeStyles(this.stylesJson[`${checkedTemplate.value || "default"}`]);
        this._setPlacementCss(style, "#placement_css");
        this._applyStyle(value);
        break;
      case "template":
        style = this._removeStyles(this.stylesJson[`${value}`]);
        this._setPlacementCss(style, "#placement_css");
        break;
      case "position":
        this._applyPosition();
        break;
      case "custom-css-enabled":
        if (event.target.checked) {
          const css = document.querySelector('[data-live-preview-target="custom-css"]').value;
          this._setPlacementCss(css, "#custom_css");
        } else {
          this._setPlacementCss("", "#custom_css");
        }
        break;
      default:
        break;
    }
  }

  showCompletionText() {
    this.placement.querySelector(".gc-text").classList.toggle("hidden");
    this.placement.querySelector(".gc-completion-text").classList.toggle("hidden");
  }

  previewImage(file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = this.placement.querySelector(".gc-image");
      img.src = reader.result;
      const imgPreview = this.element.querySelector(".image-preview");
      imgPreview.src = reader.result;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async getMarkdown(text, selector) {
    const segments = window.location.pathname.split("/");
    const newPath = segments.slice(0, 4).join("/") + "/markdown";
    const response = await post(newPath, { body: JSON.stringify({ markdown_text: text }) });
    if (response.ok) {
      const body = await response.json;
      if (body.html != null) {
        this.placement.querySelector(selector).innerHTML = body.html;
      }
    }
  }

  _applyStyle(style) {
    if (style === "navbar") {
      this._makeNavbar();
    } else if (style === "floating") {
      this._makeFloating();
    } else {
      this._makeInline();
    }
    this._applyPosition();
  }

  _removeStyles(style) {
    return style?.replace(/fixed/gm, "absolute").replace(/100vw/g, "100%");
  }

  _resetPlacement() {
    this.gcBox.style.transform = "";
    this.gcBox.style.width = "";
    this.gcBox.style.marginTop = "";
    this.gcBox.style.marginBottom = "";
  }

  _makeNavbar() {
    this.gcBox.style.transform = "scale(0.64) translate(-28%)";
    this.gcBox.style.marginTop = "-10px";
    this.gcBox.style.marginBottom = "-10px";
    this.gcBox.style.width = "156%";
    this._applyPosition();
  }

  _makeFloating() {
    this._resetPlacement();
  }

  _makeInline() {
    this._resetPlacement();
    this.gcBox.style.position = "absolute";
    this.gcBox.style.top = "50%";
    this.gcBox.style.width = "100%";
    this.gcBox.style.transform = "translateY(-50%)";
  }

  _applyPosition() {
    setTimeout(() => {
      const select = document.querySelector('select[data-disabled-input="true"]:not([disabled])');
      if (select == null) return;
      const value = select.value;
      this.gcBox.style.top = "";
      this.gcBox.style.bottom = "";
      this.gcBox.style.left = "";
      this.gcBox.style.right = "";

      const positionStyles = this.POSITIONS[value];
      Object.entries(positionStyles).forEach(([position, value]) => {
        this.gcBox.style[position] = value;
      });
    }, 0);
  }

  _getCheckedStyle() {
    return document.querySelector('[data-live-preview-target="style"]:checked');
  }

  _getCheckedTemplate() {
    return document.querySelector('[data-live-preview-target="template"]:checked');
  }

  _setPlacementCss(css, selector) {
    this.placement.querySelector(`#live-preview-placement  ${selector}`).textContent = css;
  }
}
