import ColorPicker from "@stimulus-components/color-picker";

export default class extends ColorPicker {
  static values = {
    brandColor: String
  };

  connect() {
    super.connect();
    if (!this.inputTarget.value) {
      this.picker._clearColor();
    }
  }

  // Callback when the color is saved
  onSave(color) {
    super.onSave(color);
    this.inputTarget.dispatchEvent(new Event("change"));
  }

  // You can override the swatches with this getter.
  // Here are the default options.
  get swatches() {
    return [this.brandColorValue, "#000000", "#D3D3D3"];
  }
}
