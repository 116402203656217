import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["operator", "operatorOptions", "fakeOperator"];
  static values = { type: String };
  connect() {
    const initialValue = this.operatorTarget.value;
    const element = this.operatorOptionsTarget.querySelector(`li[data-value=${initialValue}]`);
    element?.click();
  }

  select(event) {
    this.operatorOptionsTarget.querySelectorAll("li[data-active]").forEach((element) => {
      element.removeAttribute("data-active");
    });
    event.currentTarget.setAttribute("data-active", "");
    this.fakeOperatorTarget.innerText = event.currentTarget.querySelector("#innerText").innerText;
    this.operatorTarget.value = event.currentTarget.dataset.value;

    if (this.typeValue === "time") {
      this.updateTimeVisibility(event);
    }
  }

  updateTimeVisibility(event) {
    const form = event.currentTarget.closest("form");
    const start = form.querySelector("#visibility_rule_value_start");
    const end = form.querySelector("#visibility_rule_value_end");

    const hideElement = (element) => {
      element.style.display = "none";
      element.disabled = true;
    };

    const showElement = (element) => {
      element.style.display = "block";
      element.disabled = false;
    };

    switch (event.currentTarget.dataset.value) {
      case "after":
        hideElement(end);
        showElement(start);
        end.classList.remove("rounded-r-md");
        break;
      case "before":
        showElement(end);
        end.classList.add("rounded-r-md");
        hideElement(start);
        break;
      case "between":
        showElement(start);
        showElement(end);
        start.classList.remove("rounded-r-md");
        break;
    }
  }
}
