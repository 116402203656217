import { Controller } from "@hotwired/stimulus";
import lunr from "lunr";

export default class extends Controller {
  static targets = ["search", "option", "empty", "hiddenInput", "listbox"];
  static values = { options: Array, valueType: { type: String, default: "string" } };

  outsideClickListener = (e) => {
    if (e.target != this.searchTarget) {
      this.hideBox();
    }
  };

  customKeysListener = (e) => {
    let currentFocus = this.listboxTarget.querySelector("[data-focus]");
    let nextFocus;
    if (e.key === "ArrowUp") {
      e.preventDefault();
      // Up arrow
      nextFocus = currentFocus.previousElementSibling;
      while (nextFocus && nextFocus.classList.contains("hidden")) {
        nextFocus = nextFocus.previousElementSibling;
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      // Down arrow
      nextFocus = currentFocus.nextElementSibling;
      while (nextFocus && nextFocus.classList.contains("hidden")) {
        nextFocus = nextFocus.nextElementSibling;
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      // Enter
      currentFocus.click();
    }

    if (nextFocus) {
      nextFocus.scrollIntoView({ behavior: "smooth", block: "nearest" });
      currentFocus.removeAttribute("data-focus");
      nextFocus.setAttribute("data-focus", "");
    }
  };

  connect() {
    this.initializeIndex();
    addEventListener("click", this.outsideClickListener);
  }

  disconnect() {
    removeEventListener("click", this.outsideClickListener);
  }

  select(event) {
    let previousElement;
    switch (this.valueTypeValue) {
      case "string":
        previousElement = this.element.querySelector("[data-selected]");
        if (previousElement) {
          previousElement.removeAttribute("data-selected");
        }
        this.hiddenInputTarget.value = event.currentTarget.id;
        break;
      case "array":
        // this will eventually be used for multi-select now it's just a duplication of the string case
        previousElement = this.element.querySelector("[data-selected]");
        if (previousElement) {
          previousElement.removeAttribute("data-selected");
        }
        this.hiddenInputTarget.value = event.currentTarget.id;

      default:
        break;
    }

    event.currentTarget.setAttribute("data-selected", "");
    this.hideBox();
    this.searchTarget.value = event.currentTarget.dataset.name;
  }

  handleMouseLeave(event) {
    event.currentTarget.removeAttribute("data-focus");
  }

  handleMouseEnter(event) {
    const previousElement = this.element.querySelector("[data-focus]");
    if (previousElement) {
      previousElement.removeAttribute("data-focus");
    }

    event.currentTarget.setAttribute("data-focus", "");
  }

  initializeIndex() {
    const data = this.optionsValue;
    this.index = lunr(function () {
      this.ref("reference");
      this.field("name");
      this.field("title");
      data.forEach((doc) => this.add(doc));
    });
  }

  search(event) {
    event.preventDefault();
    event.stopPropagation();
    this.showBox();

    if (this.searchTarget.value === "") {
      this.showAllResults();
      return;
    }

    const inputValue = this.searchTarget.value.replace(/[\W_]+/g, " ").trim();
    const results = this.index.search(`${inputValue}^100 ${inputValue}*^10`);
    const references = results.map((result) => result.ref);
    this.hideResults(references);
    this.manageEmptyState(references);
  }

  hideResults(references) {
    this.optionTargets.forEach((element, i) => {
      if (references.includes(element.dataset.reference)) {
        element.classList.remove("hidden");
      } else {
        element.classList.add("hidden");
      }
    });
    let visibleLiElements = this.listboxTarget.querySelectorAll("li:not(.hidden)");
    if (visibleLiElements.length > 0) {
      this.listboxTarget.querySelector("[data-focus]")?.removeAttribute("data-focus");
      visibleLiElements[0].scrollIntoView({ behavior: "smooth", block: "nearest" });
      visibleLiElements[0].setAttribute("data-focus", "");
    }
  }

  manageEmptyState(references) {
    if (references.length === 0) {
      this.emptyTarget.classList.remove("hidden");
    } else {
      this.emptyTarget.classList.add("hidden");
    }
  }

  showAllResults() {
    this.optionTargets.forEach((element) => {
      element.classList.remove("hidden");
    });
  }

  showBox() {
    this.listboxTarget.classList.remove("hidden");
    let selectedOption = this.listboxTarget.querySelector("[data-selected]");
    if (selectedOption) {
      selectedOption.scrollIntoView({ block: "nearest" });
    }
    document.addEventListener("keydown", this.customKeysListener);
  }

  hideBox() {
    this.listboxTarget.classList.add("hidden");
    document.removeEventListener("keydown", this.customKeysListener);
  }

  toggleBox(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.listboxTarget.classList.contains("hidden")) {
      this.showBox();
    } else {
      this.hideBox();
    }
  }
}
