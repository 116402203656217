import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { amount: Number, currency: String, allCurrencies: Object };
  connect() {
    let locale = Object.keys(this.allCurrenciesValue).find(
      (key) => this.allCurrenciesValue[key].toLowerCase() === this.currencyValue.toLowerCase()
    );

    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: this.currencyValue,
      minimumFractionDigits: 2
    });
    this.element.textContent = formatter.format(this.amountValue / 100);
  }
}
