import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'showMoreButton']
  static values = { autoclose: Boolean }

  outsideClickListener = (e) => {
    if (!this.element.contains(e.target) || e.target.target == '_blank') {
      this.contentTarget.classList.add('hidden')
      this.element.classList.remove('active')
    }
  }

  connect() {
    if (this.autocloseValue) {
      addEventListener('click', this.outsideClickListener)
    }
  }

  toggle(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.autocloseValue) {
      document.querySelectorAll('[data-controller="click-toggle"].active').forEach((element) => {
        if (element === this.element) return
        element.querySelector('[data-action="click-toggle#toggle"]').click()
      })
    }
    this.contentTarget.classList.toggle('hidden')
    this.element.classList.toggle('active')
  }

  close() {
    this.contentTarget.classList.add('hidden')
    this.element.classList.remove('active')
  }

  // I had to do a new action to toggle hidden class to multiple elements
  // since when I wrapped the tags inside a div I broke all the styles :(
  multipleToggle(e) {
    const target = e.target
    e.preventDefault()
    e.stopPropagation()

    this.contentTargets.forEach((element) => {
      element.classList.toggle('hidden')
    })

    if (!this.hasShowMoreButtonTarget) return

    if (target.dataset.state === 'closed') {
      target.dataset.state = 'open'
      target.innerHTML = 'Show less'
    } else {
      target.dataset.state = 'closed'
      target.innerHTML = 'Show more'
    }
  }

  disconnect() {
    removeEventListener('click', this.outsideClickListener)
    this.contentTarget.classList.add('hidden')
    this.element.classList.remove('active')
  }
}
